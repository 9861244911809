import App from "@/components/App.vue";
import { initCharts } from "@/lib/chart";
import type { Hello } from "@/lib/models";
import { createRouter } from "@/lib/router";
import { getError } from "@/lib/util";
import { deepConvertDates } from "@/lib/util/deepConvertDates";
import { apiAuth } from "@/vendor/api";
import { Config } from "@js-from-routes/client";
import { camelCase, deepConvertKeys } from "@js-from-routes/core";
import { createPinia } from "pinia";
import { createApp } from "vue";
import Vue3GoogleLogin from "vue3-google-login";

declare global {
  interface Window {
    alertsEnv: {
      google_client_id: string;
    };
  }
}

//
// initializers go here
//

initCharts();

// js-from-routes Config. By default, js-from-routes will deep convert snake
// case (rails style) to camel case (js style). We also want to deep convert
// date strings to dates.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
Config.deserializeData = (object: any) => {
  object = deepConvertKeys(object, camelCase);
  object = deepConvertDates(object);
  return object;
};

//
// onStart
//

async function onStart() {
  // REMIND: error handling? this will throw on failure
  const hello = await apiAuth.hello();

  const app = createApp(App);
  app.provide<Hello>("hello", hello);
  app.use(createPinia());
  app.use(createRouter());
  app.use(Vue3GoogleLogin, { clientId: window.alertsEnv.google_client_id });

  // simple error handler
  app.config.errorHandler = (err) => {
    flash(`Uh oh - ${getError(err)}`);
    throw err;
  };

  app.mount("#app");
}

// our <script> tag is in <head>, so the DOM may not be ready yet
document.addEventListener("DOMContentLoaded", onStart);
