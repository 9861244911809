<template>
  <div
    class="fixed left-0 right-0 top-0 z-50 flex h-[var(--nav-height)] items-center justify-between bg-base-100 bg-paper/80 px-4 backdrop-blur-sm sm:px-8"
    :class="{ 'border-b': scrolled }"
  >
    <RouterLink :to="home">
      <img src="@/assets/wordmark.svg" class="h-6 sm:h-8" />
    </RouterLink>

    <!-- links -->
    <nav class="flex items-center gap-3 rounded-2xl border px-3 py-2 text-xs sm:gap-4 sm:px-4 sm:text-sm">
      <template v-if="!authStore.loggedIn">
        <RouterLink :to="Routes.signup()" class="btn btn-primary">Sign up</RouterLink>
        <RouterLink :to="home" class="link-hover link">Home</RouterLink>
        <RouterLink :to="Routes.about()" class="link">About</RouterLink>
      </template>
      <template v-else>
        <RouterLink :to="home" class="link">Dashboard</RouterLink>
        <a class="link" @click="onLogout">Log out</a>
      </template>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { useEventListener } from "@vueuse/core";

//
// state
//

const router = useRouter();
const authStore = useAuthStore();
const scrolled = ref<boolean>();
const home = computed(() => (authStore.loggedIn ? Routes.dashboard() : Routes.root()));

//
// events
//

async function onLogout() {
  authStore.logout();
  await router.push(Routes.root());
  flash("You have been logged out.");
}

// note when navbar becomes sticky
useEventListener("scroll", () => (scrolled.value = window.scrollY > 24));
</script>

<style scoped lang="postcss">
a.link {
  @apply link-hover;
}
.btn {
  @apply btn-xs h-7 min-h-7 rounded-xl px-3;
}
</style>
