<template>
  <AppNav />
  <div class="main" :class="{ 'main-full-screen': route.meta.fullScreen }">
    <Suspense>
      <div><RouterView :key="route.path" /></div>
    </Suspense>
  </div>
  <AppFooter />

  <!-- flash -->
  <AppFlash />
</template>

<script setup lang="ts">
import AppFlash from "./AppFlash.vue";
import AppNav from "./AppNav.vue";
import AppFooter from "./AppFooter.vue";

//
// state
//

const route = useRoute();
</script>

<style scoped lang="postcss">
.main {
  min-height: calc(100vh - var(--nav-height) - var(--footer-height));

  &:not(.main-full-screen) {
    @apply container mx-auto max-w-screen-lg px-4 py-6 sm:px-8;
  }
}
</style>
