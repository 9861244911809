import type { ChartSeries } from "@/lib/models";
import type { ChartData, ChartOptions } from "chart.js";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import mapValues from "lodash-es/mapValues";

//
// Style constants
//

const FONT_COLOR = "#0C0C0C";
const FONT_FAMILY = "Roboto Condensed";
const FONT_SIZE = 12;
const GRID_COLOR = "#E0E0E0";

export const PALETTES = mapValues(
  {
    // Based on economist style guide
    econ: "#006BA2 #3EBCD2 #EBB434 #9d394b #0f9ca3 #ac8e9a",

    // Based on d3 color schemes
    d3Category10: "#1f77b4 #ff7f0e #2ca02c #d62728 #9467bd #8c564b #e377c2 #7f7f7f #bcbd22 #17becf",
    d3Observable10: "#4269d0 #efb118 #ff725c #6cc5b0 #3ca951 #ff8ab7 #a463f2 #97bbf5 #9c6b4e #9498a0",
    d3Paired: "#a6cee3 #1f78b4 #b2df8a #33a02c #fb9a99 #e31a1c #fdbf6f #ff7f00 #cab2d6 #6a3d9a #ffff99 #b15928",
    d3Pastel1: "#fbb4ae #b3cde3 #ccebc5 #decbe4 #fed9a6 #ffffcc #e5d8bd #fddaec #f2f2f2",
    d3Set1: "#e41a1c #377eb8 #4daf4a #984ea3 #ff7f00 #ffff33 #a65628 #f781bf #999999",
    d3Set2: "#66c2a5 #fc8d62 #8da0cb #e78ac3 #a6d854 #ffd92f #e5c494 #b3b3b3",
    d3Tableau10: "#4e79a7 #f28e2c #e15759 #76b7b2 #59a14f #edc949 #af7aa1 #ff9da7 #9c755f #bab0ab",

    // Based on d3Paired, but swich order of each pair to put darker color first
    d3PairedInv: "#1f78b4 #a6cee3 #33a02c #b2df8a #e31a1c #fb9a99 #ff7f00 #fdbf6f #6a3d9a #cab2d6 #b15928 #ffff99",
  },
  (colors) => colors.split(" "),
);

export const DEFAULT_PALETTE: keyof typeof PALETTES = "d3PairedInv";

// Type guard
function isPaletteName(palette: string): palette is keyof typeof PALETTES {
  return palette in PALETTES;
}

//
// setup
//

export function initCharts() {
  ChartJS.register(
    BarElement,
    CategoryScale,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    SubTitle,
    TimeScale,
    Title,
    Tooltip,
  );
  ChartJS.defaults.font.family = FONT_FAMILY;
  ChartJS.defaults.font.size = FONT_SIZE;
  ChartJS.defaults.color = FONT_COLOR;
}

//
// data
//

export function buildChartData(
  data: ChartSeries[],
  options: { palette?: string } = {},
): ChartData<"bar"> & ChartData<"line"> {
  // labels is the union of all the keys in the data objects
  const labels = Array.from(new Set(data.flatMap((series) => Object.keys(series.data))));

  // TODO: smart sorting

  const palette = options.palette ?? DEFAULT_PALETTE;
  if (!isPaletteName(palette)) throw new Error("Palette not found");

  const colors = PALETTES[palette];

  const datasets = data.map((series, index) => ({
    label: series.label,
    data: labels.map((label) => series.data[label] ?? 0),

    // for bar chart
    backgroundColor: colors[index % colors.length],

    // for line chart
    borderColor: colors[index % colors.length],
  }));

  return { labels, datasets };
}

//
// chart options
//

type OptionParams = {
  title: string;
  subtitle?: string;

  // show legend?
  legend?: boolean;

  // for bar chart
  stacked?: boolean;

  // for line chart
  points?: boolean;
  rounded?: boolean;
};

export function barChartOptions({ legend, stacked, subtitle, title }: OptionParams): ChartOptions<"bar"> {
  return commonChartOptions({ legend, stacked, subtitle, title });
}

export function lineChartOptions({ legend, points, rounded, stacked, subtitle, title }: OptionParams) {
  return {
    ...commonChartOptions({ legend, stacked, subtitle, title }),

    //
    // show/hide points
    //

    pointRadius: points ? undefined : 0,
    pointHoverRadius: points ? undefined : 0,

    //
    // rounded lines
    //

    cubicInterpolationMode: rounded ? "monotone" : undefined,
    tension: rounded ? 0.4 : 0,
  };
}

function commonChartOptions(options: OptionParams): ChartOptions<"bar"> & ChartOptions<"line"> {
  return {
    animation: false,
    plugins: {
      legend: {
        display: !!options.legend,
        labels: {
          boxHeight: 12,
          boxWidth: 12,
        },
      },
      subtitle: {
        align: "start",
        display: !!options.subtitle,
        text: options.subtitle,
        font: {
          weight: "normal",
        },
        padding: {
          top: 2,
          bottom: options.legend ? 0 : 16,
        },
      },
      title: {
        align: "start",
        display: true,
        text: options.title,
        font: {
          size: 16,
          weight: "bold",
        },
        padding: {
          bottom: options.subtitle || options.legend ? 0 : 16,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        type: "time",
        grid: {
          display: false,
        },
        stacked: !!options.stacked,
        time: {
          minUnit: "day",
        },
        ticks: {
          autoSkip: true,
          // maxRotation: 0, // disable rotation
          autoSkipPadding: 5, // more min space between ticks
        },
      },
      y: {
        position: "right",
        border: { display: false },
        beginAtZero: true,
        grid: {
          color: GRID_COLOR,
        },
        stacked: !!options.stacked,
        ticks: {
          maxTicksLimit: 5, // plenty of space between ticks
          precision: 0, // integer only
          autoSkip: false, // simplify tick math since maxTicksLimit is set
        },
      },
    },
    layout: {
      padding: 0,
    },
  };
}
