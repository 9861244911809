// JsFromRoutes CacheKey 958c7940011e5366e04f3597e301e9e7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  hello: /* #__PURE__ */ definePathHelper('get', '/api/auth/hello'),
  signup: /* #__PURE__ */ definePathHelper('post', '/api/auth/signup'),
  login: /* #__PURE__ */ definePathHelper('post', '/api/auth/login'),
  loginWithGoogle: /* #__PURE__ */ definePathHelper('post', '/api/auth/login_with_google'),
  logout: /* #__PURE__ */ definePathHelper('post', '/api/auth/logout'),
  forgot: /* #__PURE__ */ definePathHelper('post', '/api/auth/forgot'),
  reset: /* #__PURE__ */ definePathHelper('post', '/api/auth/reset'),
  resetCheck: /* #__PURE__ */ definePathHelper('post', '/api/auth/reset_check'),
}
