import isArray from "lodash-es/isArray";
import isPlainObject from "lodash-es/isPlainObject";
import isString from "lodash-es/isString";
import mapValues from "lodash-es/mapValues";

//
// Find JSON date strings in object, convert to Dates
//

/* eslint-disable @typescript-eslint/no-explicit-any */

export function deepConvertDates(object: any) {
  if (isArray(object)) {
    return object.map((item): any => deepConvertDates(item));
  }

  if (isPlainObject(object)) {
    return mapValues(object, (value, key): any => {
      if (isDateKey(key)) {
        if (isString(value)) {
          return new Date(value);
        }
      }
      return deepConvertDates(value);
    });
  }

  return object;
}

function isDateKey(key: string) {
  return key.match(/[a-z]At$/) || key.match(/^(date|timestamp)/);
}
